<template>
	<div>
		<div class="box">
		   <CustomTable
				id_table="export_perso_config"
				:items="exportPerso"
				:busy.sync="table_busy"
				primaryKey="export_id"
				:hide_if_empty="true"
			/>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Accounting from '@/mixins/Accounting.js'
	import Navigation from "@/mixins/Navigation.js";
	import { EventBus } from 'EventBus'

	export default {
		name: '',
		mixins: [Accounting, Navigation],
		data () {
			return {
				table_busy: true,
				exportPerso: [],
				events_tab: {
					'TableAction::goToSaveExport': this.saveExport
			   }
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loadConfig();
			},

			async loadConfig() {
				this.table_busy = true
				this.exportPerso = [];

				let exportConfig = await this.getExportAccountingPlan(this.$route.params.accountingplan_id)

				Object.keys(exportConfig).forEach(config => {
					//Je parcours toutes les configs par défaut de Groomy
					exportConfig[config][0].forEach(c => {
						let defaut = c
						defaut.export_label = this.$t("compta."+defaut.export_label)
						if(defaut.default == true)
						{
							defaut.export_selected = this.getTrad("compta.export.export_selectionne")
						}
						this.exportPerso.push(defaut)
					})

					if (exportConfig[config][1]) {
						exportConfig[config][1].forEach(c => {
							if(c.default == true)
							{
								c.export_selected = this.getTrad("compta.export.export_selectionne")
							}
							this.exportPerso.push(c);
						})
					}
				})

				this.table_busy = false
			},

			async saveExport(exportPerso) {
				let res = await this.selectExportPerso(exportPerso.export_id, this.$route.params.accountingplan_id)
				if(res){
					this.successToast("toast.info_modif_succes")
					let config = await this.loadExportConfig()
					if(config)
						this.loadConfig()
				}
			}
		},

		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
		}
	}

</script>